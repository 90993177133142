<template>
  <div class="page-container">
    <div class="wrapper">
      <div class="intro-content">
        <div class="intro-word">

          <div class="intro-t-wrapper">
            <img class="intr-title-pic" src="../images/introduce-title-pic.png"/>
            <div class="intro-title">
              彩票介绍
            </div>
          </div>
          
          <div class="intro-words">
            <div class="head">
              台湾星海彩<span v-if="type == 2">简介</span>
            </div>
            <div class="h-content" v-if="type != 2">
              台湾星海彩票始于1987年以扶老、助残、济<br/>
              困为宗旨，开发了一条特色的福利彩票发展之<br/>
              路，随后又设立了星海彩票发行中心作为发行<br/>
              机构。
            </div>
            <div class="h-content cont2" v-if="type == 2">
              <p>
                台湾星海彩票始于1987年，以“团结社会各界热心社会福利事<br/>
                业的人士，发扬社会主义人道精神，筹集社会福利资金。兴办残<br/>
                疾人，老年人，故而福利事业和帮助有困难的人”为使命，以<br/>
                “扶老、助残、济困”为宗旨，开启了一条特色的福利彩票发<br/>
                展之路，随后又设立了星海彩票发行中心作为发行机构。
              </p>
              <p>
                30多年来，全国各地福利彩票公益金累计资助兴办各类福利事业<br />
                项目3万多个，主要用于资助农村敬老院、社会福利院、光荣院<br />
                以及精神病院、荣军医院、流浪乞讨人员救助设施、残疾人事业、<br />
                特殊教育学校、慈善医院、社区服务中心、殡葬设施等福<br />
                利服务设施的兴建、改建、扩建，发展社会福利公益事业。星<br />
                海福彩公益金的投入为保障社会弱势群体和困难群体的基本生<br />
                活权益发挥了重要作用，充分体现了福利彩票“取之于民，用<br />
                之于民”的使命担当。
              </p>
            </div>
            <div class="intro-l-wrapper">
              <div class="i-l-item">
                <div>始于</div>
                <div>1987年</div>
              </div>
              <div class="i-l-item">
                <div>宗旨</div>
                <div>扶老.助残.济困</div>
              </div>
              <div class="i-l-item">
                <div>机构</div>
                <div>台湾星海彩中心</div>
              </div>
            </div>
          </div>
        </div>
        <div class="intro-pic" :class="{type2:type == 2}">
          <img src="../images/intro-dec-pic.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  props:{
    type:Number,
  }
}
</script>

<style scoped>
.page-container{
  width:100%;
}
.wrapper{
  width:100%;
  background-color:rgb(233, 245, 253);
  padding-bottom: 20px;
}
.intro-content{
  width:96%;
  display:flex;
  align-items: center;
  border-radius:.24rem;
  margin:auto;
  background-color:white;
}
.intro-word{
  width:50%;
  height:100%;
  padding-bottom:.58rem;
}
.intro-t-wrapper{
  position:relative;
  margin:.28rem 0 0 .3rem;
}
.intro-t-wrapper .intr-title-pic{
  height:.52rem;
}
.intro-title{
  color: #A3A3A3;
  position: absolute;
  top:.06rem;
  left:.12rem;
  font-size:.28rem;
}
.intro-pic{
  width:50%;
  height:5.20rem;
  border-radius:0 .21rem .21rem 0;
  overflow: hidden;
}
.intro-pic.type2{
  border-radius:0;
}
.intro-pic img{
  width:100%;
  height:100%;
}
.intro-words{
  padding:.1rem 0 0 .48rem;
}
.intro-words .head{
  font-size:.28rem;
  font-weight:bold;
  margin:.38rem 0 .18rem 0;
}
.intro-words .h-content{
  color:#A3A3A3;
  font-size:.28rem;
}
.intro-words .h-content.cont2{
  font-size:.18rem;
  line-height:.36rem;
}
.intro-l-wrapper{
  display:flex;
  margin-top:.54rem;
}
.intro-l-wrapper .i-l-item{
  text-align: center;
  font-size:.24rem;
  font-weight: bold;
  width:34.33%;
  position:relative;
}
.intro-l-wrapper .i-l-item::after{
  content: ' ';
  display: block;
  height: 147%;
  width: 2px;
  background: #E5E0F3;
  position: absolute;
  top: -.18rem;
  right: -.05rem;
  transform: rotateZ(10deg);
}
.intro-l-wrapper .i-l-item > div:last-child{
  color:#A7A6FC;
  margin-top:.1rem;
}
.intro-l-wrapper .i-l-item:first-child{
  width:26%;
}
.intro-l-wrapper .i-l-item:last-child{
  width:36.33%;
}
.intro-l-wrapper .i-l-item:last-child::after{
  display:none;
}

</style>