<template>
  <div class="red-blue-green-w">
    <div class="r-b-g-title">
      <div>
        Red blue green
      </div>
      <div class="se-title">
        <div class="important-title">
          2024年波色对照表
        </div>
        <div class="red-blue-green-middle-title">
          |红波.蓝波.绿波|
        </div>
      </div>
      
    </div>
    <div class="wave-row-item red">
      <div class="w-r-i-title">
        红<br />
        波
      </div>
      <div class="w-r-i-bet">
        1.2.7.8.12.13.18.19.23.24.29.30.34.35.40.45.46
      </div>
      <div class="zodiac-list-w">
        <div>鼠</div>
        <div>牛</div>
        <div>虎</div>
        <div>兔</div>
        <div>龙</div>
        <div>蛇</div>
      </div>
    </div>

    <div class="wave-row-item blue">
      <div class="w-r-i-title">
        蓝<br />
        波
      </div>
      <div class="w-r-i-bet">
        3.4.9.10.14.15.20.25.26.31.36.37.41.42.47.48
      </div>
      <div class="zodiac-list-w duichong">
        <div>对<br>冲</div>
        <div>对<br>冲</div>
        <div>对<br>冲</div>
        <div>对<br>冲</div>
        <div>对<br>冲</div>
        <div>对<br>冲</div>
      </div>
    </div>

    <div class="wave-row-item green">
      <div class="w-r-i-title">
        绿<br />
        波
      </div>
      <div class="w-r-i-bet">
        5.6.11.16.17.21.22.27.28.32.33.38.39.43.44.49
      </div>
      <div class="zodiac-list-w">
        <div>马</div>
        <div>羊</div>
        <div>猴</div>
        <div>鸡</div>
        <div>狗</div>
        <div>猪</div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.se-title{
  display:flex;
}
.red-blue-green-w{
  background-color:#E8F5FD;
  font-size:.24rem;
  padding-top:.36rem;
  padding-bottom:.56rem;
}
.r-b-g-title{
  padding:.36rem 0 0 .99rem;
  font-size: .45rem;
  margin-bottom:.22rem;
}
.important-title{
  color: #086763;
  font-weight:bold;
}
.red-blue-green-middle-title{
  margin-bottom:.26rem;
}
.wave-row-item{
  display:flex;
  width: 92%;
  margin: auto;
  color:white;
  border-radius:.1rem;
  height:1.68rem;
  margin: 0 auto .14rem auto;
  align-items: center;
  padding-left:.2rem;
  position:relative;
}
.wave-row-item.red{
  background:#FC0204;
}
.wave-row-item.green{
  background:#027F01;
}
.wave-row-item.blue{
  background:#0602ED;
}
.wave-row-item .w-r-i-bet{
  font-size:.3rem;
  text-align: left;
  border-left:.03rem solid;
  margin-left:.12rem;
  padding-left:.32rem;
  line-height:1.68rem;
}
.wave-row-item > div:first-child{
  font-size:.28rem;
  font-weight: bold;
}
.w-r-i-title{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.zodiac-list-w{
  display:flex;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  right:0;
}
.zodiac-list-w > div{
  width:.48rem;
  border-left:.02rem solid white;
  height:1.68rem;
  display:flex;
  align-items: center;
  justify-content: center;
}
</style>