<template>
  <div class="record">
    <div class="date-time">
      2024年3月24日&nbsp;17:25:30&nbsp;星期日
    </div>
    <div class="wrapper">
      <div class="r-logo-w">
        <img src="../images/lottery-logo.png" alt="">
        <div>台湾星海彩</div>
      </div>
      <div class="record-container">
        
        <div class="l-t-inner-w">

          <div class="record-date-wrapper">
            <div>
              第<span class="red">219</span>期
            </div>
            <div>
              本期开奖时间&nbsp;:&nbsp;2024-8-30&nbsp;20:10
            </div>
            <div class="record-link blue" @click="bonusRecordClick">
              [开奖记录]
            </div>
          </div>

          <div class="bonus-i-wrapper">
            <div style="margin-right:-.17rem;">
              <div class="b-i-mtitle">
                平码
              </div>
              <div class="b-i-xtitle">
                平肖
              </div>
            </div>
            <div v-for="bonus of bonusCodeList">
              <div class="bonus-img" :class="bonus.styleColor">
                <div class="b-i-w-c">
                  {{bonus.code}}
                </div>
              </div>
              <div class="b-i-zodiac">
                {{bonus.zodiac}}
              </div>
            </div>
            <div>
              <div class="plus-w">
                +
              </div>
              <div>
              </div>
            </div>
            <div>
              <div class="bonus-img" :class="tailObj.styleColor">
                <div class="b-i-w-c">
                  {{tailObj.code}}
                </div>
              </div>
              <div class="b-i-zodiac">
                {{tailObj.zodiac}}
              </div>
            </div>
          </div>

        </div>
        <div class="next-bonus-dtime">下期开奖时间&nbsp;:&nbsp;2024-8-30&nbsp;20:10</div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      bonusCodeList:[
        {
          code:'02',
          zodiac:'鼠',
          styleColor:'red'
        },
        {
          code:'45',
          zodiac:'牛',
          styleColor:'red'
        },
        {
          code:'28',
          zodiac:'虎',
          styleColor:'green'
        },
        {
          code:'10',
          zodiac:'蛇',
          styleColor:'blue'
        },
        {
          code:'19',
          zodiac:'马',
          styleColor:'red'
        },
        {
          code:'24',
          zodiac:'兔',
          styleColor:'red'
        }
      ],
      tailObj:{
        code:'37',
        zodiac:'豬',
        styleColor:'blue'
      },
    }
  },
  mounted(){

  },
  methods:{
    bonusRecordClick(){
      this.$router.push('/record')
    }
  }
}
</script>

<style scoped>
.record{
  width:13.66rem;
  margin:auto;
  background-color:rgb(233, 245, 253);
  padding:.18rem .26rem .16rem .26rem;
  color:rgb(116, 122, 126);
  font-size:.26rem;
}
.date-time{
  margin-bottom:.08rem;
}
.wrapper{
  display:flex;
}
.r-logo-w{
  border-radius:.16rem 0 0 .16rem;
  background-color:rgb(16, 29, 58);
  width:3rem;
  height:2.89rem;
  font-size: .36rem;
  color:white;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: .10rem;
  flex-shrink: 0;
}
.r-logo-w > img{
  width:1.68rem;
}
.r-logo-w > div{
  margin-top:.3rem;
}
.record-container{
  width:100%;
  background-color:white;
  border-radius:0 .16rem .16rem 0;
  overflow:hidden;
  padding:.2rem .4rem;
}
.record-container .record-date-wrapper{
  display:flex;
  justify-content: space-between;
}

.record-container .record-date-wrapper .red{
  color:rgb(254, 34, 96);
}
.record-container .record-date-wrapper .blue{
  color:rgb(33, 147, 219);
}
.record-container .record-date-wrapper .record-link{
  cursor: pointer;
}
.bonus-i-wrapper{
  width:100%;
  color:black;
  margin:.26rem auto .2rem auto;
  display:flex;
  justify-content:space-between;
}
.next-bonus-dtime{
  text-align: center;
}
.b-i-mtitle,.b-i-xtitle{
  display:flex;
  justify-content: center;
  align-items: center;
  height:.68rem;
  font-size: .3rem;
}
.bonus-img{
  width: .8rem;
  height: .77rem;
  display: flex;
  border-radius: 50%;
  border: .02rem solid white;
  box-shadow: 0 0 .05rem 0 rgba(0,0,0,.4);
  background-size: 100% 100%;
}
.bonus-img.red{
  background-image:url(../images/n-bg-red.png);
}
.bonus-img.green{
  background-image:url(../images/n-bg-green.png);
}
.bonus-img.blue{
  background-image:url(../images/n-bg-blue.png);
}
.b-i-zodiac{
  width:100%;
  text-align: center;
  text-shadow:0 0 3px #6f6f6f;
  font-size:.33rem;
}
.plus-w{
  font-size:.37rem;
  font-weight: bold;
}
.b-i-w-c{
  margin:auto;
  font-size:.3rem;
  font-weight: bold;
}
</style>