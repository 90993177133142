<template>
  <div class="page">
    <div class="five-element">
      <div>
        <div style="font-size:.5rem;">The five elements</div>
        <div class="s-f-e-title">
          <div class="five-no-dz">五行号码对照</div>
          <div>|2024年|</div>
        </div>
        <div class="xsxk">
          <div class="wx-item-w">
            <div class="xsxk-title">
              <img class="xsxk-t-img" src="../images/sxxs.png" />
              <div>
                五行相生
              </div>
            </div>
            <div class="xsxk-item-row">
              【金生水】→【水生木】
            </div>
            <div class="xsxk-item-row">
              【木生火】→【火生土】
            </div>
            <div class="xsxk-item-row">
              【土生金】
            </div>
          </div>
          <div class="wx-item-w">
            <div class="xsxk-title">
              <img class="xsxk-t-img" src="../images/sxxk.png" />
              <div>
                五行相克
              </div>
            </div>
            <div class="xsxk-item-row">
              【金克木】→【木克土】
            </div>
            <div class="xsxk-item-row">
              【土克水】→【水克火】
            </div>
            <div class="xsxk-item-row">
              【火克金】
            </div>
          </div>
        </div>
      </div>

      <div class="five-ele-graphic">
        <div class="f-e-i-wrapper">
          <div class="se-e-graphic">
            <div class="se-i-graphic">
              <div class="t-i-graphic"></div>
            </div>
          </div>
        </div>

        <div class="five-i-c-w">
          <div class="five-t-wrapper">
            <div>
              土
            </div>
            <img src="../images/ele-ico-earth.png" alt="">
          </div>

          <div class="bet-code">
            04 05 18 19 26 27 34 35 48 49
          </div>

        </div>
        <div class="five-i-c-w metal">
          <div class="five-t-wrapper">
            <div>
              金
            </div>
            <img src="../images/ele-ico-metal.png" alt="">
          </div>

          <div class="bet-code">
            02 03 10 11 24 25 32 33 40 41
          </div>

        </div>
        <div class="five-i-c-w wood">
          <div class="five-t-wrapper">
            <div>
              木
            </div>
            <img src="../images/ele-ico-wood.png" alt="">
          </div>

          <div class="bet-code">
            06 07 14 15 22 23 36 37 44 45
          </div>

        </div>
        <div class="five-i-c-w water">
          <div class="five-t-wrapper">
            <div>
              水
            </div>
            <img src="../images/ele-ico-water.png" alt="">
          </div>
          <div class="bet-code">
            12 13 20 21 28 29 42 43
          </div>
        </div>
        <div class="five-i-c-w fire">
          <div class="five-t-wrapper">
            <div>
              火
            </div>
            <img src="../images/ele-ico-fire.png" alt="">
          </div>
          <div class="bet-code">
            01 08 09 16 17 30 31 38 39 46 47
          </div>
        </div>

        <div class="moon m1"></div>
        <div class="moon m2"></div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.page{
  width:100%;
  background-color:#F4F8FB;
}
.five-element{
  height:6.88rem;
  text-align: center;
  font-size:.26rem;
  padding-top:.3rem;
  padding-bottom:.2rem;
  display:flex;
  justify-content: center;
  align-items: center;
}
.five-element .five-no-dz{
  color:#076763;
  font-weight: bold;
}
.five-element .xsxk{
  display:flex;
  justify-content:center;
  margin-top:.32rem;
}
.s-f-e-title{
  margin-bottom:.88rem;
  display:flex;
  font-size:.41rem;
  justify-content: center;
}
.xsxk .wx-item-w{
  width:3.05rem;
  height:2.56rem;
  background: white;
  box-shadow: 0 0.09rem 0.1rem 0 rgba(0,0,0,.2);
  color: #8E8E8E;
  font-size: .18rem;
  font-weight: bold;
}
.xsxk .wx-item-w:first-child{
  margin-right:.2rem;
}
.xsxk .xsxk-title{
  color:#000000;
  font-weight: bold;
  font-size:.25rem;
  display:flex;
  align-items: center;
  justify-content: center;
  padding-top:.32rem;
  margin-bottom:.12rem;
}
.xsxk .xsxk-title .xsxk-t-img{
  width:.68rem;
  margin-right:.18rem;
}

.xsxk .xsxk-item-row{
  margin-bottom:.16rem;
}

.five-ele-graphic{
  background:linear-gradient(#00FE12,#3EAE1C);
  width:5.52rem;
  height:5.52rem;
  margin: 0 0 0 .52rem;
  border-radius:50%;
  display:flex;
  position:relative;
}
.five-ele-graphic .f-e-i-wrapper{
  margin:auto;
  border-radius: 50%;
  width: 98.8%;
  height: 98.8%;
  background:#F4F8FB;
  display:flex;
}
.f-e-i-wrapper .se-e-graphic{
  width:60%;
  height:60%;
  display:flex;
  margin:auto;
  border-radius: 50%;
  background:linear-gradient(#00FE12,#3EAE1C);
}
.f-e-i-wrapper .se-e-graphic .se-i-graphic{
  border-radius: 50%;
  width:98%;
  height:98%;
  background:#F4F8FB;
  margin:auto;
  display:flex;
}

.f-e-i-wrapper .se-e-graphic .se-i-graphic .t-i-graphic{
  width:18%;
  height:18%;
  background:url(../images/five-center-g.png);
  background-size:100% 100%;
  margin:auto;
  border-radius: 50%;
}

.five-ele-graphic .five-i-c-w{
  width:1.23rem;
  height:1.23rem;
  border-radius: 50%;
  box-shadow: 0 0 .1rem 0 rgba(0,0,0,.2);
  position: absolute;
  background-color: white;
  left: -.23rem;
  top:1.08rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.five-i-c-w.metal{
  left:2.28rem;
  top:.62rem;
}
.five-i-c-w.wood{
  left:3.8rem;
  top:0;
}
.five-i-c-w.water{
  left:1.24rem;
  top:3.24rem;
}
.five-i-c-w.fire{
  left:3.58rem;
  top:2.5rem;
}

.five-ele-graphic .moon{
  width:.3rem;
  height:.3rem;
  background:white;
  border-radius:50%;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
  position:absolute;
}
.five-ele-graphic .moon.m1{
  left: .02rem;
  top: 3.53rem;
}
.five-ele-graphic .moon.m2{
  left:3.11rem;
  top:-.1rem;
}
.five-i-c-w .five-t-wrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.five-t-wrapper img{
  width:.38rem;
}
.five-i-c-w .bet-code{
  width:81%;
  overflow: hidden;
  white-space: wrap;
  font-size:.16rem;
  line-height:.19rem;
  margin-top:.08rem;
  font-weight: bold;
}
</style>