<template>
  <div class="banner">
    <img class="bg" v-if="!type" src="../images/banner.png" alt="">
    <img class="bg horizontal" v-if="type == 1" src="../images/banner-h.png" alt="">
    <img class="bg horizontal" v-if="[-1,2].includes(type)" src="../images/banner-2.png" alt="">
    <img class="bg horizontal" v-if="type == 3" src="../images/banner-gallery.png" alt="">
    <nav>
      <div class="nav-list-wrapper">
        <img class="logo-img" src="../images/logo.png" />
        <div v-for="navItem of navItemList" class="nav-item" :class="{curr:getIsCurrent(navItem)}"  @click="navItemClick(navItem)">
          {{navItem.footerTitle}}
        </div>
        <img class="global-icon" src="../images/global.png" />
        <img class="search-i" src="../images/search-i.png" />
      </div>
    </nav>
    <div class="banner-w-container" :class="{horizontal:[1,3].includes(type)}" v-if="![-1,2].includes(type)">
      <img class="banner-word" src="../images/banner-w-word.png" alt="">
      <div class="c-y-luck">
        challenge&nbsp;your&nbsp;luck
      </div>
    </div>
    <div class="banner-w-container news" v-if="[2].includes(type)">
      <div>NEWS</div>
      <div>新闻资讯</div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'HomeView',
  props:{
    type:Number,
  },
  data(){
    return {
      navItemList:[
        {
          footerTitle:'首页',
          path:'',
        },
        {
          footerTitle:'开奖记录',
          path:'record',
        },
        {
          footerTitle:'正版图库',
          path:'gallery',
        },
        {
          footerTitle:'彩票介绍',
          path:'introduce',
        },
        {
          footerTitle:'新闻资讯',
          path:'hotnews',
        },
        {
          footerTitle:'开奖公告',
          path:'openbonus',
        },
        {
          footerTitle:'五行号码',
          path:'five',
        },
        {
          footerTitle:'生肖属性',
          path:'zodiac',
        },
        {
          footerTitle:'波色对照',
          path:'ballcolor',
        }
      ],
    }
  },
  mounted(){
    
  },
  methods:{
    navItemClick(item){
      this.$router.push(`/${item.path}`)
    },
    getIsCurrent(nav){
      if('/'+nav.path === this.$route.path){
        return true;
      }else{

      }
      return 0;
    }
  },
  components: {
  }
}
</script>
<style scoped>
.main .banner{
  width:13.66rem;
  margin:auto;
  position:relative;
}
.main .banner .bg{
  width:100%;
  height:5.14rem;
}
.main .banner .bg.horizontal{
  height:3.58rem;
}
.main .banner .c-y-luck{
  width:100%;
  text-align: center;
  color:white;
  font-size:.28rem;
}
.main .banner .banner-word{
  width:100%;
  display:block;
}
.banner-w-container{
  width:5.09rem;
  top:1.86rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.banner-w-container.horizontal{
  display:flex;
  align-items: flex-end;
  left:1.15rem;
  transform:translateX(0);
}
.banner-w-container.horizontal .c-y-luck{
  padding-bottom:.2rem;
}
.banner-w-container.news{
  font-size:.38rem;
  color:white;
  text-align:center;
  font-weight:bold;
  line-height:.39rem;
}
nav{
  width:100%;
  height:1.23rem;
  position:absolute;
  top:0;
  left:0;
  display:flex;
  align-items: center;
  padding-left:.38rem;
}
nav .logo-img{
  width:2.64rem;
}
nav .nav-item{
  color:white;
  margin-left:.27rem;
  font-size:.18rem;
  cursor: pointer;
  padding-bottom:.04rem;
}
nav .nav-item.curr{
  border-bottom:.02rem solid;
}
nav .nav-list-wrapper{
  display:flex;
  align-items: flex-end;
}
.global-icon{
  width:.41rem;
  height:.41rem;
  margin-left:.4rem;
  cursor: pointer;
}
.search-i{
  width:.37rem;
  height:.38rem;
  margin-left:.4rem;
  cursor: pointer;
}
</style>