import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OpenBonusRecordView from '../views/OpenBonusRecordView.vue'
import IntroduceView from '../views/IntroduceView.vue'
import GalleryView from '../views/GalleryView.vue'
import HotnewsView from '../views/HotnewsView.vue'
import OpenbonusView from '../views/OpenbonusView.vue'
import FiveView from '../views/FiveView.vue'
import ZodiacView from '../views/ZodiacView.vue'
import BallcolorView from '../views/BallcolorView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/record',
    name:'record',
    component:OpenBonusRecordView,
  },
  {
    path:'/introduce',
    name:'introduce',
    component:IntroduceView,
  },
  {
    path:'/gallery',
    name:'gallery',
    component:GalleryView,
  },
  {
    path:'/hotnews',
    name:'hotnews',
    component:HotnewsView,
  },
  {
    path:'/openbonus',
    name:'openbonus',
    component:OpenbonusView,
  },
  {
    path:'/five',
    name:'five',
    component:FiveView,
  },
  {
    path:'/zodiac',
    name:'zodiac',
    component:ZodiacView,
  },
  {
    path:'/ballcolor',
    name:'ballcolor',
    component:BallcolorView,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
