<template>
  <div class="main">
    <Banner :type="1" />

    <div class="site-map-wrapper">
      <SiteMap :siteList="localSite" />
    </div>

    <div class="dynamic-wrapper">
      <div class="year-link-w">
        <div class="y-l-item">2024年开奖记录</div>
        <div class="y-l-item">2023年开奖记录</div>
        <div class="y-l-item">2022年开奖记录</div>
        <div class="y-l-item">2021年开奖记录</div>
      </div>
      <DynamicUpdate :type="'open'" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import DynamicUpdate from '../components/DynamicUpdate.vue'
import Footer from '../components/Footer.vue'
import SiteMap from '../components/SiteMap.vue'

export default {
  data(){
    return {
      localSite:[
        {
          title:'开奖记录',
          path:'',
        }
      ]
    }
  },
  mounted(){
    
  },
  methods:{

  },
  components: {
    Banner,
    DynamicUpdate,
    Footer,
    SiteMap,
  }
}
</script>

<style scoped>
.main{
  width: 13.66rem;
  margin: auto;
  background:#E9F5FD;
}

.site-map-wrapper{
  padding:.08rem 0 0 .6rem;
}

.dynamic-wrapper{
  display:flex;
  justify-content: center;
  color:#949CA1;
  font-size:.18rem;
}
.year-link-w{
  width:2.66rem;
  padding-top:.3rem;
}
.year-link-w .y-l-item{
  font-size:.22rem;
  cursor: pointer;
  margin-top:.24rem;
}
</style>
