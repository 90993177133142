<template>
  <div class="zodiacs-duizhao">
    <div class="z-d-zhao">
      <div class='block-title'>
        The 12 Chinese Zodiacs
      </div>
      <div class="se-title">
        <div class='block-title t1'>
          生肖对照
        </div>
        <div class='block-title t2'>
          |2024年|
        </div>
      </div>
    </div>
    

    <div class="zodiac-list-wrapper">
      <div class="zodiac-code-wrapper" v-for="zItem of zodiacMapCodeList">
        <div>
          <img class="zodiac-item-pic" :src="getZodiacImage(zItem.zodiac)" alt="">
        </div>
        <div class="zodiac-word">{{ zItem.zodiac }}</div>
        <div class="z-b-c-wrapper">
          <div class="zodiac-bet-code" :class="codeItem.color" v-for="codeItem of zItem.codeList">
            {{codeItem.code}}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default{
  data(){
    return {
      zodiacStr:'鼠牛虎兔龙蛇马羊猴鸡狗猪',
      zodiacMapCodeList:[],
    }
  },
  mounted(){
    this.generationData();
  },
  methods:{
    getColorByCode(code){
      let num = 1 * code;
      if ([1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46].includes(num)) {
        return 'red'
      } else if ([3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48].includes(num)) {
        return 'blue'
      } else {
        return 'green'
      }
    },
    // 获取当前农历年份生肖
    getCurrentYearZodiac() {
      const currentDate = new Date();

      let fYear = currentDate.getFullYear();
      let month = currentDate.getMonth();
      let date = currentDate.getDate();
      let nongli = lunarFun.gregorianToLunal(fYear, month + 1, date);

      const currentYear = nongli[0];
      const zodiacs = this.zodiacStr.split('');
      return zodiacs[Math.floor((currentYear - 4) % 12)];
    },
    generationData(){
      let zodiacMapCode = window.globalConfig.playWayMapNumber;

      let current = this.getCurrentYearZodiac();
      let currIndex = this.zodiacStr.indexOf(current);
      let s = this.zodiacStr.slice(0,currIndex);
      let f = this.zodiacStr.slice(currIndex);
      let zodiacStr1 = f += s;
      let zodiacList = zodiacStr1.split('');

      this.zodiacMapCodeList = zodiacList.map(zodiac => {
        let noList = zodiacMapCode[zodiac].split(',');
        return {
          zodiac,
          codeList:noList.map(no => {
            return {
              code:no,
              color:this.getColorByCode(no)
            }
          })
        }
      });
    },
    getZodiacImage(zodiac){
      switch(zodiac){
        case '鼠':
          return '/images/zodiac-pic-mouse.png';
        case '牛':
          return '/images/zodiac-pic-bull.png';
        case '虎':
          return '/images/zodiac-pic-tigger.png';
        case '兔':
          return '/images/zodiac-pic-robbit.png';
        case '龙':
          return '/images/zodiac-pic-dragon.png';
        case '蛇':
          return '/images/zodiac-pic-snek.png';
        case '马':
          return '/images/zodiac-pic-horse.png';
        case '羊':
          return '/images/zodiac-pic-sheep.png';
        case '猴':
          return '/images/zodiac-pic-monkey.png';
        case '鸡':
          return '/images/zodiac-pic-chicken.png';
        case '狗':
          return '/images/zodiac-pic-dog.png';
        case '猪':
          return '/images/zodiac-pic-pig.png';
      }
    }
  }
}
</script>

<style scoped>
.zodiacs-duizhao .z-d-zhao{
  font-size:.45rem;
  padding:.78rem 0 0 .99rem;
}
.block-title.t1{
  color:#076762;
  font-weight:bold;
}
.zodiac-list-wrapper{
  width:100%;
  display:flex;
  overflow-x:auto;
  overflow-y:hidden;
  padding:.2rem .2rem .2rem 0;
}
.zodiac-list-wrapper .zodiac-code-wrapper{
  flex-shrink: 0;
  background:white;
  width:.97rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-left:.15rem;
  padding-top:.06rem;
  padding-bottom:.1rem;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
}
.zodiac-list-wrapper .zodiac-code-wrapper .zodiac-item-pic{
  height:.64rem;
}
.zodiac-list-wrapper .zodiac-code-wrapper .zodiac-word{
  font-size:.4rem;
}
.zodiac-list-wrapper .zodiac-code-wrapper .zodiac-bet-code{
  width:.48rem;
  height:.48rem;
  background-size:100% 100%;
  border-radius:50%;
  font-size:.28rem;
  display:flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-weight: bold;
}
.zodiac-bet-code.red{
  background-image:url(../images/bet-code-red.png);
}
.zodiac-bet-code.green{
  background-image:url(../images/bet-code-green.png);
}
.zodiac-bet-code.blue{
  background-image:url(../images/bet-code-blue.png);
}
.z-b-c-wrapper{
  width:100%;
  height:2.45rem;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.se-title{
  display:flex;
}
</style>