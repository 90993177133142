<template>
  <div class="main">
    <Banner :type="3" />

    <div class="site-map-wrapper">
      <SiteMap :siteList="localSite" />
    </div>
    
    <div class="page-title">
      <div class="main-edition">
        正版图库&nbsp;绝版机密
      </div>
      <div class="main-title">
        Out of print secret
      </div>
      <div class="gallery-table-wrap">
        <div class="tab-title" :class="{curr:picTab == 0}" @click="picTabClick">
          热门图库
        </div>
        <div class="tab-title" :class="{curr:picTab == 1}" @click="picTabClick">
          推荐图库
        </div>
      </div>

      <div class="galler-item-wrapper">
        <div class="gallery-item-container" v-for="item of galleryItemList">
          <div class="g-i-c-pic">
            图片
          </div>
          <div class="g-i-date-num">
            第{{item.dataNum}}期
          </div>
        </div>
      </div>

    </div>

    <Footer />
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import DynamicUpdate from '../components/DynamicUpdate.vue'
import Footer from '../components/Footer.vue'
import SiteMap from '../components/SiteMap.vue'

export default {
  data(){
    return {
      localSite:[
        {
          title:'正版图库',
          path:'',
        }
      ],
      picTab:0,
      galleryItemList:[
        {
          dataNum:'041'
        },
        {
          dataNum:'042'
        },
        {
          dataNum:'043'
        },
        {
          dataNum:'044'
        },
        {
          dataNum:'045'
        },
        {
          dataNum:'046'
        },
        {
          dataNum:'047'
        },
        {
          dataNum:'048'
        },
      ]
    }
  },
  mounted(){
    
  },
  methods:{
    picTabClick(){
      this.picTab = this.picTab ? 0 : 1;
    }
  },
  components: {
    Banner,
    DynamicUpdate,
    Footer,
    SiteMap,
  }
}
</script>
<style scoped>
.main{
  width: 13.66rem;
  margin: auto;
  background:#E9F5FD;
}

.site-map-wrapper{
  padding:.08rem 0 0 .6rem;
}

.page-title{
  width:100%;
  text-align: center;
}
.main-edition{
  color: #1079FB;
  font-size:.36rem;
  font-weight:bold;
}
.main-title{
  color:#828282;
  text-align: center;
  font-size:.36rem;
}
.gallery-table-wrap{
  width:auto;
  display:flex;
  justify-content: center;
}
.gallery-table-wrap .tab-title{
  width:3rem;
  height:.88rem;
  display:flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size:.3rem;
  color:#949CA1;
  position:relative;
  border-right:.03rem solid;
  cursor: pointer;
  margin:.16rem 0;
}
.gallery-table-wrap .tab-title:last-child{
  border-right:none;
}
.gallery-table-wrap .tab-title::after{
  content:' ';
  display:block;
  width:90%;
  height:.12rem;
  position:absolute;
  bottom:0;
}
.gallery-table-wrap .tab-title.curr::after{
  background:#6FA3A8;
}
.galler-item-wrapper{
  display:flex;
  flex-wrap:wrap;
}
.gallery-item-container{
  border:1px solid #989898;
  background-color:#FFFFFF;
  width:23%;
  height:3.6rem;
  flex-shrink: 0;
  margin:0 0 .26rem .22rem;
  position:relative;
}
.gallery-item-container .g-i-c-pic{
  width:100%;
  height:2.08rem;
  background:gray;
  margin-top:.4rem;
}
.gallery-item-container .g-i-date-num{
  position: absolute;
  bottom: .14rem;
  font-size: .22rem;
  color: #828282;
  left: 0;
  width: 100%;
  text-align: center;
}
</style>