<template>
  <div class="site-wrapper">
    <div class="site-item-w" v-for="(site,i) of mapList">

      <span v-if="i > 0" class="site-item-split">&gt;</span>
      
      <span v-if="!site.path" class="site-link">
        {{site.title}}
      </span>
      <a :href="`/#${site.path}`" class="site-link" v-if="site.path">
        {{site.title}}
      </a>
    </div>
  </div>
</template>

<script>
export default{
  props:{
    siteList:Array,
  },
  data(){
    return{
      mapList:[
        {
          title:'首页',
          path:'/'
        }
      ]
    }
  },
  mounted(){
    if(this.siteList){
      this.mapList = this.mapList.concat(this.siteList);
    }
  }
}
</script>

<style scoped>
.site-wrapper{
  width:auto;
  display:flex;
  align-items: center;
}
.site-wrapper .site-item-w{
  font-size:.16rem;
  display:flex;
  align-items: center;
}
.site-link{
  text-decoration:none;
  color:black;
}
.site-item-split{
  margin:0 .06rem;
  font-size:.28rem;
}
</style>
