<template>
  <div class="page-wrap" :class="type">
    <div class="n-w-title" :class="type">
      <div class="n-w-t-w">
        <div>
          实时动态更新
        </div>
        <div class="news-zh-cn">
          开奖公告
        </div>
      </div>
      <div style="font-size:.18rem;color:#959595;">更多&gt;&gt;</div>
    </div>

    <div class="history-head history-row" :class="type">
      <div class="date-time" :class="type">开奖时间</div>
      <div class="data-date-no">期数</div>
      <div class="zhengma" style="padding-top:0;">正码</div>
      <div class="tema">特码</div>
      <div class="his-zodiac" :class="type">生肖</div>
      <div class="his-ball-color" :class="type">波色</div>
    </div>
    <div class="history-row" :class="type" v-for="hItem of historyDataList">
      <div class="date-time" :class="type">{{hItem.dateTime}}</div>
      <div class="data-date-no">{{hItem.dateNum}}</div>
      <div class="zhengma" style="display:flex;">
        <div v-for="zodiacCode of hItem.codeList">
          <div class="bonus-code red">{{zodiacCode.code}}</div>
          <div class="bonus-zodiac">{{zodiacCode.zodiac}}</div>
        </div>
      </div>
      <div class="tema">
        <div class="data-ceil">
          <div class="bonus-code green">
            <div class="plus-flag">
              +
            </div>
            {{hItem.specialNo.code}}
          </div>
          <div class="bonus-zodiac">
            {{hItem.specialNo.zodiac}}
          </div>
        </div>
      </div>
      <div class="his-zodiac" :class="type">{{hItem.zodiac}}</div>
      <div class="his-ball-color" :class="type">{{hItem.ballColor}}</div>
    </div>
    <div v-if="type == 'open'">
      分页组件
    </div>
  </div>
</template>

<script>
export default{
  props:{
    type:String,
  },
  data(){
    return{
      historyDataList:[]
    }
  },
  mounted(){
    this.genData();
  },
  methods:{
    genData(){
      let zodiacStr = '鼠牛虎兔龙蛇马羊猴鸡狗猪';
      for(let i = 0,l = 11; i<l; i++){
        let data = {
          dateTime:'2024-3-15',
          dateNum:'031',
          codeList:[
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            }
          ],
          specialNo:{
            code:37,
            zodiac:'鼠'
          },
          zodiac:'猴',
          ballColor:'绿波',
        };
        this.historyDataList.push(data);
      }
    }
  }
}
</script>

<style scoped>
.page-wrap{
  background-color:#EBEBEB;
  padding-bottom:.4rem;
}
.page-wrap .n-w-title{
  display:flex;
  justify-content: space-between;
  align-items: flex-end;
  padding:0 .7rem;
}
.page-wrap .n-w-title .n-w-t-w{
  font-size:.34rem;
  padding:.35rem 0 0 .27rem;
}
.page-wrap.open{
  width:9.81rem;
  background-color:transparent;
}
.n-w-title.open{
  display:none;
}
.news-zh-cn{
  color:#005489;
  font-weight: bold;
}

.history-head{
  background:#828282 !important;
  height:.58rem;
  font-size:.2rem !important;
  font-weight:bold;
  color:#FFFFFF !important;
  margin-top:.2rem !important;
}
.history-row{
  width:90%;
  margin:0 auto;
  display:flex;
  color:#005489;
  font-size:.2rem;
  background-color:white;
}
.history-row > div{
  width:.6rem;
  display: flex;
  justify-content:center;
  align-items: center;
}
.history-row > div:last-child{
  border-right:none;
}
.history-row .zhengma{
  width:3.95rem;
  border-right:none;
  padding-top:.06rem;
  margin-left:0;
}
.history-row .zhengma > div{
  width:16.6%;
  display:flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
}
.history-row .date-time{
  width:2.8rem;
  margin:0;
}
.history-row .date-time.open{
  width:2.2rem;
}
.history-row .data-date-no{
  width:1.2rem;
  margin:0;
}
.history-row .tema{
  width:1.16rem;
  padding-right:.28rem;
  flex-shrink: 0;
  justify-content:flex-end;
}
.history-row .tema .data-ceil{
  flex-direction:column;
  padding-top:.05rem;
  align-items:center;
  display:flex;
}
.history-row .tema .data-ceil .bonus-code{
  position:relative;
}
.history-row .tema .data-ceil .bonus-code .plus-flag{
  position:absolute;
  right:.58rem;
  font-size:.31rem;
  color:#9A9A9B;
}
.history-row .his-zodiac{
  width:1.2rem;
}
.history-row .his-ball-color{
  width:1.2rem;
}
.history-row .his-zodiac.open{
  width:.58rem;
}
.history-row .his-ball-color.open{
  width:.58rem;
}
.history-row .bonus-zodiac{
  font-weight:bold;
  font-size:.18rem;
  margin-top:.07rem;
  margin-bottom:.06rem;
}
.history-row .bonus-code{
  width:.44rem;
  height:.44rem;
  margin-top:.12rem;
  display:flex;
  justify-content: center;
  align-items: center;
  background-size:100% 100%;
  font-weight:bold;
  font-size:.18rem;
  color:#090909;
}
.history-row .bonus-code.red{
  background-image:url(../images/n-bg-red.png)
}
.history-row .bonus-code.green{
  background-image:url(../images/n-bg-green.png)
}
.history-row .bonus-code.blue{
  background-image:url(../images/n-bg-blue.png)
}
.history-row.open{
  width: 100%;
  font-size:.18rem;
  background-color:transparent;
}
.history-row.open > div{
  border-right:1px solid #BFBFBF;
}
.history-row.open > div.zhengma{
  border-right:none;
}
.history-row.open > div:last-child{
  border-right:none;
}
.history-row.open .bonus-code{
  width:.36rem;
  height:.36rem;
}
.history-head.open{
  background-color:#B3D5CB !important;
  color:#000000 !important;
  font-size:.16rem !important;
  height:.46rem;
  margin-top:0 !important;
}
</style>
