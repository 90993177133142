<template>
  <div class="main">
    <Banner :type="-1" />

    <div class="site-map-wrapper">
      <SiteMap :siteList="localSite" />
    </div>
    
    <div class="introduce-container">
      <Introduction :type="2" />
    </div>

    <Footer />
  </div>
</template>

<script>
  import Banner from '../components/Banner.vue'
  import DynamicUpdate from '../components/DynamicUpdate.vue'
  import Introduction from '../components/Introduction.vue'
  import Footer from '../components/Footer.vue'
  import SiteMap from '../components/SiteMap.vue'

  export default {
    data(){
      return {
        localSite:[
          {
            title:'彩票介绍',
            path:'',
          }
        ]
      }
    },
    mounted(){
      
    },
    methods:{

    },
    components: {
      Banner,
      DynamicUpdate,
      Introduction,
      Footer,
      SiteMap,
    }
  }
</script>
<style scoped>
.main{
  width: 13.66rem;
  margin: auto;
  background:#E9F5FD;
}

.site-map-wrapper{
  padding:.08rem 0 0 .6rem;
}
.introduce-container{
  padding-top:.4rem;
}
</style>