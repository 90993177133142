<template>
  <div class="main">
    <Banner />
    <Record />

    <div class="ann-con-wrapper">
      <div class="announcement">
        <div class="ann-wrap">
          公告
        </div>
        <div class="ann-icon-wrapper"></div>
        <div class="ann-content">
          尊敬的用户，台湾星海彩官方网站主线路为:<a href="http://www.twxhcp8.com" target="_blank">www.twxhcp8.com</a>
        </div>
      </div>
    </div>

    <Introduction />
    <Gallery />
    <News />
    <DynamicUpdate />
    <FiveEle />
    <ZodiacDuizhao />
    <ColorBo />
    <Footer />
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import Record from '../components/Record.vue'
import Introduction from '../components/Introduction.vue'
import DynamicUpdate from '../components/DynamicUpdate.vue'
import News from '../components/News.vue'
import Gallery from '../components/Gallery.vue'
import FiveEle from '../components/FiveEle.vue'
import ZodiacDuizhao from '../components/ZodiacDuizhao.vue'
import ColorBo from '../components/ColorBo.vue'
import Footer from '../components/Footer.vue'


export default {
  name: 'HomeView',
  data(){
    return {
      
    }
  },
  mounted(){
    
  },
  methods:{

  },
  components: {
    Banner,
    Record,
    Introduction,
    DynamicUpdate,
    News,
    Gallery,
    FiveEle,
    ZodiacDuizhao,
    ColorBo,
    Footer,
  }
}
</script>

<style scoped>
.main{
  width:13.66rem;
  margin:auto;
}

.ann-con-wrapper{
  width:100%;
  background-color: rgb(233, 245, 253);
  padding:1px;
}
.announcement{
  width:96%;
  margin:.2rem auto;
  padding:.2rem .3rem;
  border-radius:.1rem;
  background-color:#FAFAFA;
  display:flex;
  align-items: center;
}
.ann-wrap{
  border: solid 3px;
  border-radius:.04rem;
  width:.9rem;
  height:.5rem;
  color: #BAA27E;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:.26rem;
  box-shadow:0 0 .1rem 0 rgba(0,0,0,.4);
}
.ann-icon-wrapper{
  width:.7rem;
  height:.4rem;
  margin-right:.1rem;
  background-image:url(../images/ann-icon.png);
  background-size:100% 100%;
}
.ann-content{
  font-size:.3rem;
  color:#CE2413;
}
.ann-content a{
  color:#CE2413;
  text-decoration: none;
}

</style>