<template>
  <div class="page-container">
    <div class="news-wrapper">
      <div class="n-w-title">
        <div class="n-w-t-w">
          <div>
            NEWS
          </div>
          <div class="news-zh-cn">
            新闻动态
          </div>
        </div>
        <div style="font-size:.18rem;">更多&gt;&gt;</div>
      </div>

      <div class="news-list-wrapper">
        <div class="n-l-c-wrapper">
          <div class="news-item-a">
            <div class="n-p-image">图片</div>
            <div>通胀疑虑挥之不去</div>
            <div class="a-t-a">美股道指早盘跌150点</div>
            <div class="a-t-b">台积电ADR跌逾2%</div>
            <div class="a-t-c">2024-03-15</div>
          </div>
          <div class="news-item-a">
            <div class="n-p-image">图片</div>
            <div>3/15大乐透的发!</div>
            <div class="a-t-a">今彩539头奖800万</div>
            <div class="a-t-b">南投1注独得</div>
            <div class="a-t-c">2024-03-15</div>
          </div>
        </div>
        <div class="n-l-list-wrapper">
          <div class="n-item-list" v-for="n of 6">
            <div class="n-i-l-title">高雄母子坠海！驾驶直冲</div>
            <div class="n-i-l-time">2024-03-15</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<style scoped>
.page-container{
  width:100%;
}
.news-wrapper .n-w-title{
  display:flex;
  justify-content: space-between;
  align-items: flex-end;
  padding:0 .22rem;
}
.news-wrapper .n-w-title .n-w-t-w{
  font-size:.34rem;
  padding:.35rem 0 0 .74rem;
}
.news-zh-cn{
  color:#005489;
  font-weight: bold;
}
.news-list-wrapper{
  width:100%;
  display:flex;
  margin-top:.2rem;
  justify-content:space-between;
  padding-bottom:.4rem;
}
.n-l-c-wrapper{
  display:flex;
  padding-left:.1rem;
}
.n-l-c-wrapper .news-item-a{
  margin-left:.2rem;
  font-size:.3rem;
  font-weight:bold;
}
.n-l-c-wrapper .n-p-image{
  width:4.04rem;
  height:3.02rem;
  background:black;
  margin-bottom: 18px;
}
.n-l-c-wrapper .a-t-a{
  color:#737373;
  margin-top:.18rem;
}
.n-l-c-wrapper .a-t-b{
  color:#737373;
  margin-top:.18rem;
}
.n-l-c-wrapper .a-t-c{
  color:#B8B8B8;
  margin-top:.35rem;
}
.n-l-list-wrapper{
  margin-top:.1rem;
  padding-top:.1rem;
}
.n-i-l-title{
  font-size:.3rem;
  font-weight: bold;
}
.n-i-l-time{
  font-size:.3rem;
  color:#B8B8B8;
}
.n-item-list{
  margin-bottom:.12rem;
}
</style>