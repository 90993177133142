<template>
  <div class="main">
    <Banner :type="2" />
    <div class="site-map-wrapper">
      <SiteMap :siteList="localSite" />
    </div>
    
    <div class="news-list-wrapper">
      <div v-for="news of newsList" class="news-wrapper">
        <div class="news-pic-w">
          图片
        </div>
        <div class="news-item-w">
          <div>舍得离开房间是打开链接发深刻的理解弗兰克是的尽量快点</div>
          <div style="text-align: right;">2024年08月08日</div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import Footer from '../components/Footer.vue'
import SiteMap from '../components/SiteMap.vue'

export default {
  data(){
    return {
      localSite:[
        {
          title:'生肖属性',
          path:'',
        }
      ],
      newsList:[
        {

        },
        {

        },
        {

        },
        {

        },
        {

        },
        {

        }
      ]
    }
  },
  mounted(){
    
  },
  methods:{
    
  },
  components: {
    Banner,
    Footer,
    SiteMap,
  }
}
</script>
<style scoped>
.main{
  width: 13.66rem;
  margin: auto;
  background:#E9F5FD;
}

.site-map-wrapper{
  padding:.08rem 0 0 .6rem;
}

.news-list-wrapper{
  margin:.56rem 0;
}
.news-wrapper{
  width:9rem;
  height:2.28rem;
  margin:auto;
  border-bottom:.03rem solid rgb(181,187,191);
  display:flex;
  justify-content: space-between;
  margin:0 auto .08rem auto;
  cursor: pointer;
}
.news-wrapper .news-pic-w{
  width:35%;
  height:98%;
  background: #ffb300;
}
.news-wrapper .news-item-w{
  width:63%;
  height:98%;
  font-size:.22rem;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  padding:.1rem 0 .16rem 0;
}
.news-wrapper:last-child{
  border-bottom:none;
}
</style>