<template>
  <div class="gallery">
    <div class="head">
      <div class="multiple-title">
        <div style="color:black;">Taiwan picture</div>
        <div style="color:#076762;font-weight:bold;">正版图库</div>
        <div style="color:#424242;">台湾星海彩&nbsp;&nbsp;绝密图报</div>
      </div>
      <div class="more-link">
        <span>更多&gt;&gt;</span>
      </div>
    </div>

    <div class="pic-item-list">
      <div class="pic-item-wrap" v-for="n of 6"></div>
    </div>

  </div>
</template>


<style scoped>
.gallery{
  width:100%;
  background-image:url(../images/gallery-back.png);
  background-size:100% 100%;
  padding-bottom: .34rem;
}
.gallery .head{
  width:100%;
  height:2.15rem;
  font-size:.24rem;
}
.gallery .pic-item-list{
  display:flex;
  justify-content:center;
}
.pic-item-wrap{
  width:1.88rem;
  height:4rem;
  box-shadow:0 0 .1rem 0 rgba(0,0,0,.4);
  margin-left:.3rem;
  background:white;
}
.pic-item-wrap:first-child{
  margin-left:0;
}
.multiple-title{
  font-size:.34rem;
  padding:.38rem 0 0 .72rem;
}
.more-link{
  text-align: right;
  color:white;
  padding-right:.45rem;
}
</style>